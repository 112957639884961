import React, { useState } from "react";
import "./styles.scss";
import circle from "../../../assets/images/apartamentos/detail/circle.svg"
import wp from "../../../assets/images/quospaces/whatsapp.svg"
import Slider from "react-slick";
import axios from "axios";

const Detail = (props) => {
  const {
    selectedAparment
  } = props;
  let slides = [selectedAparment.image1, selectedAparment.image2, selectedAparment.image3, selectedAparment.image4, selectedAparment.image5]
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 3000,
  };
  const settingsMobile = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
  };
  const [isSending, setIsSending] = useState(false);
  const [validated, setValidated] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [rent, setRent] = useState();
  const [amueblado, setAmueblado] = useState();
  const [apto, setApto] = useState(selectedAparment.apt_name);
  const setNameValidate = (value) => {
    if (!/[0-9]$/.test(value)) {
      setName(value)
    }
  }

  const nameRegex = RegExp(
    /^[A-Za-z]{3,}/
  )

  const emailRegex = RegExp(
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  );

  const phoneRegex = RegExp(
    /^[0-9]{8}/
  )

  const fakeSend = () => {
    if (
      nameRegex.test(name) &&
      emailRegex.test(email) &&
      phoneRegex.test(phone)
    ) {
      apartmentForm(name, phone, email, rent, amueblado, apto)
      setIsSending(true)
      setTimeout(() => {
        setIsSending(false);
        setValidated(true);
        setTimeout(() => setValidated(false), 2000)
      }, 3000)
    } else {
      alert("Por favor ingrese todos los campos")
    }
  };

  const apartmentForm = (name, mail, phone, compra, amueblado, apto) => {
    const formData = new FormData();
    name && formData.append("name", name);
    mail && formData.append("email", mail);
    phone && formData.append("phone", phone);
    compra && formData.append("rent", compra);
    amueblado && formData.append("furniture", amueblado);
    formData.append("comments", apto);
    formData.append("form", "4");
    axios.post(
      "https://api-ecommerce.devpackgroup.com/api/v1/send_email_quo/",
      formData,
      {
        headers: {
          // "Authorization": "Token " + this.userToken,
        }
      }
    ).then(function () {

    }).catch(function () {

    });
  }

  return (
    <div className='divContainer-apartments'>
      <div className='row'>
        <div className='col col-lg-5 offset-lg-1 colum'>
          <div className='circle'>
            <img src={circle} />
          </div>
          <div className='row'>
            <div className='col-sm col-lg mt-4 offset-lg-1 col-sm offset-1'>
              <p className='titleCard'>{selectedAparment.apt_name}</p>
            </div>
          </div>
          <div className='row'>
            <div className='col col-lg-5 offset-lg-1 col-sm offset-1'>
              <p className='time'>METRAJE: {selectedAparment.metraje}</p>
            </div>
          </div>
          <div className='row'>
            <div className='col col-lg-5 offset-lg-1 col-sm-2 offset-1'>
              <div className='divPhone'>
                <img src={wp} />
                <p>(+502) 5016-0612</p>
              </div>
            </div>
            <div className='col offset-lg-1 col-sm-2 offset-1  d-block d-lg-none'>
              <div className='rectatop'>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col col-dm-8 d-block d-lg-none'>

            </div>
          </div>
          <div className='row'>
            <div className='col-sm col-lg  offset-lg-1 col-sm offset-1'>
              <p className='infoAparment'>
                {selectedAparment.bathrooms > 1 ? "BAÑOS" : "BAÑO"} ({selectedAparment.bathrooms})<br></br>
                {selectedAparment.rooms > 1 ? "HABITACIONES" : "HABITACIÓN"} ({selectedAparment.rooms})<br></br>
                <div className={`${selectedAparment.parqueo > 0 ? "" : "d-none"} `}>
                  {selectedAparment.parqueo > 1 ? "PARQUEOS" : "PARQUEO"} ({selectedAparment.parqueo})  <br></br>
                </div>
                {selectedAparment.balcon === "1" ? "BALCÓN" : ""}{selectedAparment.balcon === "1" ? <br></br> : ""}
                {selectedAparment.terraza === "SI" ? "TERRAZA" : ""}{selectedAparment.terraza === "SI" ? <br></br> : ""}
                TORRE {selectedAparment.torre}
              </p>
            </div>
          </div>
          <div className='row'>
            <div className='col-10 offset-lg-1 col-lg-11 offset-1'>
              <p className='info'>

              </p>
            </div>
          </div>
          <div className='row'>
            <div className='col-10 offset-lg-1 col-sm offset-1'>
              <p className='renta-venta'>
                {selectedAparment.renta && `RENTA: ${selectedAparment.renta}`}
                <p className='renta-venta'>
                  {selectedAparment.venta && `VENTA: ${selectedAparment.venta}`}
                </p>
              </p>
            </div>
          </div>
          <div className='row'>
            <div className='col offset-lg-2 col-sm offset-1 col-lg-9 col-md-5 d-block d-lg-none'>
              <div className='rectabottom'>
              </div>
            </div>
            <div className=' d-block d-lg-none'>
              <div className='circle-bottom'>
                <img src={circle} />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col offset-lg-8 d-none d-lg-block rectabutton-web-container'>
              <div className='rectabottom-web' />
            </div>
          </div>
        </div>
        <div className='col col-lg-5 d-none d-lg-block ms-lg-4 colF'>
          <div className='form'>
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
            <div className='row'>
              <div className='col  '>
                <input
                  type="text"
                  className="input-name"
                  placeholder="NOMBRE"
                  value={name}
                  onChange={(e) => setNameValidate(e.target.value)}
                />
              </div>
              <div className='col'>
                <input
                  type="number"
                  className="input-name"
                  placeholder="TELÉFONO"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col '>
                <input
                  type="text"
                  className="input-email"
                  placeholder="CORREO ELECTRÓNICO"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col select-venta'>
                <select className='select'
                  placeholder='VENTA O RENTA'
                  onChange={(e) => setRent(e.target.value)}>
                  <option selected disabled hidden value="">COMPRA O RENTA</option>
                  <option value="Compra">COMPRA</option>
                  <option value="Renta">RENTA</option>
                </select>
              </div>
              <div className='col select-mueble'>
                <select className='select'
                  onChange={(e) => setAmueblado(e.target.value)}>
                  <option selected disabled hidden value="">AMUEBLADO</option>
                  <option value="Amueblado">SI</option>
                  <option value="No amueblado">NO</option>
                </select>
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                <textarea
                  rows="3"
                  cols="50"
                  placeholder="COMENTARIOS"
                  className=" textarea-form"
                  value={apto}
                  onChange={(e) => setApto(e.target.value)}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col '>
                <button
                  id="button"
                  type="button"
                  onClick={() => fakeSend()}
                  className={`${isSending ? "onclic" : "something"} ${validated ? "validate" : "something"} `}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${(slides[0] === "" && slides[1] === "" && slides[2] === "" && slides[3] === "" && slides[4] === "") ? "d-none":""} row`}>
        <div className='col d-none d-lg-block col-lg-6 offset-lg-3'>
          <div className='carouselApar'>
            <Slider {...settings}>
              {slides.map((value, index) =>{
                if(value !== ""){
                  return(
                    <div className={"image"} alt='' key={index}>
                      <img src={value} className='d-block ' alt='...' />
                    </div>
                  )
                }
              })}
            </Slider>
          </div>
        </div>
        <div className='col d-block d-lg-none col-lg-10 offset-lg-1'>
          <div className='carouselAparMobile'>
            <Slider {...settingsMobile}>
              <div className={"image-apt"} alt=''>
                <img src={selectedAparment.image1} className='d-block ' alt='...' />
              </div>
              <div className={"image-apt"} alt=''>
                <img src={selectedAparment.image2} className='d-block ' alt='...' />
              </div>
              <div className={"image-apt"} alt=''>
                <img src={selectedAparment.image3} className='d-block ' alt='...' />
              </div>
              <div className={"image-apt"} alt=''>
                <img src={selectedAparment.image4} className='d-block ' alt='...' />
              </div>
              <div className={"image-apt"} alt=''>
                <img src={selectedAparment.image5} className='d-block ' alt='...' />
              </div>
            </Slider>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col d-block d-lg-none'>
          <div className='form'>
            <div className='row'>
              <div className='col  '>
                <input
                  type="text"
                  className="input-name"
                  placeholder="NOMBRE"
                  value={name}
                  onChange={(e) => setNameValidate(e.target.value)}
                />
              </div>
              <div className='col'>
                <input
                  type="number"
                  className="input-name"
                  placeholder="TELÉFONO"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col '>
                <input
                  type="text"
                  className="input-email"
                  placeholder="CORREO ELECTRÓNICO"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col select-venta'>
                <select className='select'
                  placeholder='COMPRA O RENTA'
                  onChange={(e) => setRent(e.target.value)}>
                  <option selected disabled hidden value="">COMPRA O RENTA</option>
                  <option value="Compra">COMPRA</option>
                  <option value="Renta">RENTA</option>
                </select>
              </div>
              <div className='col select-mueble'>
                <select className='select'
                  onChange={(e) => setAmueblado(e.target.value)}>
                  <option selected disabled hidden value="">AMUEBLADO</option>
                  <option value="Amueblado">SI</option>
                  <option value="No amueblado">NO</option>
                </select>
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                <textarea
                  rows="3"
                  cols="50"
                  placeholder="COMENTARIOS"
                  className=" textarea-form"
                  value={apto}
                  onChange={(e) => setApto(e.target.value)}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col '>
                <button
                  id="button"
                  type="button"
                  onClick={() => fakeSend()}
                  className={`${isSending ? "onclic" : "something"} ${validated ? "validate" : "something"} `}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Detail