import React, { useRef } from "react";
import "./styles.scss";
import Detail from "./Detail"

const QuoSpaces = () => {
  const myRef = useRef(null);
  return (
    <div className="container-fluid g-0 my-container-spaces" ref={myRef}>
      <div className="row g-0">
        <div className="col my-header-spaces" >
          <div className="quo-spaces-random-box" />
          <h1>
            SPACES FOR ALL<br />KIND OF EVENTS
          </h1>
        </div>
        <Detail />
      </div>
    </div>
  )
}

export default QuoSpaces;