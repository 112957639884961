import React from "react";
import "./styles.scss";
import p01 from "../../assets/images/img1.png";
import p02 from "../../assets/images/img2.png";
import p03 from "../../assets/images/img3.jpg";
import p04 from "../../assets/images/img4.png";
import p05 from "../../assets/images/img5.png";

import p9 from "../../assets/images/img09.png";
import p10 from "../../assets/images/img10.png";
import p11 from "../../assets/images/img11.png";
import p13 from "../../assets/images/img13.png";
import p14 from "../../assets/images/img14.png";
import p15 from "../../assets/images/img15.png";
import QUO from "../../assets/images/QUO.png"
import { Parallax } from "react-scroll-parallax";
import { useHistory } from "react-router-dom";

import yellow_circle from "../../assets/images/Form/below.png"
import magenta_circle from "../../assets/images/gastronomia/circle.svg"

import QUO2 from "../../assets/images/QUO2.svg"

const Galery = (props) => {
  const {
    selectedValue,
    setSelectedValue,
  } = props;

  let history = useHistory();

  function handleChange(event) {
    setSelectedValue(event.target.value);
    history.push("/contacto");
  }
  return (
    <div className="container-fluid g-0 mycontainergalery">
      <div className="row">
        <div className="col g-0 d-none d-lg-block">
          <Parallax
            className="custom-class"
            y={[0, 0]}
            x={[35, -30]}
            tagOuter="figure"
            tagInner="div"
          >
            <h1 className="text0 ">
              <div className="divAnimated">
                <p>
                  <strong className="animetext">DISRUPT</strong> THE STATUS
                  <img className="quo animetext" src={QUO} alt="no resource" />
                </p>
              </div>
            </h1>
          </Parallax>
        </div>
        <div className="col g-0 d-block d-lg-none">
          <Parallax
            className="custom-class"
            y={[0, 0]}
            x={[-25, -135]}
            tagInner="div"
          >
            <h1 className="text0 ">
              <div className="divAnimated">
                <p>
                  <strong className="animetext">DISRUPT</strong> THE STATUS
                  <img className="quo animetext" src={QUO} alt="no resource" />
                </p>
              </div>
            </h1>
          </Parallax>
        </div>
      </div>
      <div className="row my-row-galery g-0">
        <div className="col ">
          <Parallax
            className="custom-class"
            y={[0, 0]}
            x={["-25%", "5%"]}
            tagInner="div"
          >
            <div className="row">
              <div className="col col-4 d-none d-md-block g-0">
                <div className="img-container img-square fila">
                  <img src={p05} alt="no resource" />
                </div>
              </div>
              <div className="col-2 d-none d-md-block g-0">
                <div className="img-container img-square fila">
                  <img src={p10} alt="no resource" />
                </div>
              </div>
              <div className="col col-4 d-none d-md-block g-0">
                <div className="img-container img-square fila">
                  <img src={p9} alt="no resource" />
                </div>
              </div>
              <div className="col col-2 d-none d-md-block g-0">
                <div className="img-container img-square fila">
                  <img src={p02} alt="no resource" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col col-5 d-block d-md-none g-0">
                <div className="img-container img-square fila">
                  <img src={p10} alt="no resource" />
                </div>
              </div>
              <div className="col col-5 d-block d-md-none g-0">
                <div className="img-container img-square fila">
                  <img src={p02} alt="no resource" />
                </div>
              </div>
              <div className="col col-2 d-block d-md-none g-0">
                <div className="img-container img-square fila">
                  <img src={p9} alt="no resource" />
                </div>
              </div>
            </div>
          </Parallax>
        </div>
      </div>
      <Parallax
        className="custom-class"
        y={[0, 0]}
        x={["0%", "-20%"]}
        tagInner="div"
      >
        <div className="row">
          <div className="col d-none d-md-block g-0 col-2">
            <div className="img-container img-square fila">
              <img src={p04} alt="no resource" />
            </div>
          </div>
          <div className="col d-none d-md-block g-0 col-4">
            <div className="img-container img-square fila">
              <img src={p14} alt="no resource" />
            </div>
          </div>
          <div className="col d-none d-md-block g-0 col-4">
            <div className="img-container img-square fila">
              <img src={p13} alt="no resource" />
            </div>
          </div>
          <div className="col d-none d-md-block g-0 col-2">
            <div className="img-container img-square fila">
              <img src={p10} alt="no resource" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col d-block d-md-none g-0 col-2">
            <div className="img-container img-square fila">
              <img src={p04} alt="no resource" />
            </div>
          </div>
          <div className="col d-block d-md-none g-0 col-5">
            <div className="img-container img-square fila">
              <img src={p14} alt="no resource" />
            </div>
          </div>
          <div className="col d-block d-md-none g-0 col-5">
            <div className="img-container img-square fila">
              <img src={p13} alt="no resource" />
            </div>
          </div>
        </div>
      </Parallax>
      <Parallax
        className="custom-class"
        y={[0, 0]}
        x={["-20%", "5%"]}
        tagInner="div"
      >
        <div className="row">
          <div className="col d-none d-md-block g-0 col-1">
            <div className="img-container img-square fila">
              <img src={p01} alt="no resource" />
            </div>
          </div>
          <div className="col d-none d-md-block col-4 g-0">
            <div className="img-container img-square fila">
              <img src={p03} alt="no resource" />
            </div>
          </div>
          <div className="col d-none d-md-block g-0 col-4">
            <div className="img-container img-square fila">
              <img src={p15} alt="no resource" />
            </div>
          </div>
          <div className="col d-none d-md-block g-0 col-2">
            <div className="img-container img-square fila">
              <img src={p11} alt="no resource" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col d-block d-md-none g-0 col-2">
            <div className="img-container img-square fila">
              <img src={p01} alt="no resource" />
            </div>
          </div>
          <div className="col d-block d-md-none g-0 col-5">
            <div className="img-container img-square fila">
              <img src={p15} alt="no resource" />
            </div>
          </div>
          <div className="col d-block d-md-none col-5 g-0">
            <div className="img-container img-square fila">
              <img src={p03} alt="no resource" />
            </div>
          </div>
        </div>
      </Parallax>
      <div className="row quo-munity-container justify-content-center align-content-center align-self-center">
        <p>
          <strong className="QUO">QUO</strong> es un punto de encuentro con opciones únicas <br />para disfrutar y crear recuerdos memorables.<br />Forma parte de la <img className="QUO-2" src={QUO2} alt="" />MUNIDAD
        </p>
        <div className="custom-select-wrapper">
          <select className="quo-munity-select" onChange={handleChange}>
            <option hidden disabled selected={selectedValue < 0} value className="placeholder-select">ÁREA DE INTERÉS</option>
            <option value="1" selected={selectedValue === 1} >APARTAMENTOS</option>
            <option value="2" selected={selectedValue === 2} >WORKSPACES</option>
            <option value="3" selected={selectedValue === 3} >COMERCIO</option>
            <option value="4" selected={selectedValue === 4} >GASTRONOMÍA</option>
            <option value="5" selected={selectedValue === 5} >ESPACIOS QUO</option>
            <option value="6" selected={selectedValue === 6} >GALERÍA DE ARTE</option>
          </select>
        </div>
        {/* <Link to='/contacto'>
          <p>¿QUIERES SER PARTE DE LA <strong>QUOMUNIDAD?</strong></p>
        </Link> */}
        <img src={yellow_circle} alt="" className="yellow_circle" />
        <img src={magenta_circle} alt="" className="magenta_circle" />
        <div className="random-box-right" />
        <div className="random-box-left" />
      </div>
    </div>
  );
};

export default Galery;