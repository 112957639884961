import React, { useState, useEffect } from "react";
import "./styles.scss";

import cruz from "../../assets/images/Gastronomy/cross.svg";
import Modal from "react-modal";

import redZebra from "../../assets/images/Gastronomy/Card/redZebra.png";
import dudes from "../../assets/images/Gastronomy/Card/dudes.jpg";
import meridiano from "../../assets/images/Gastronomy/Card/meridiano.jpg";
import whisky from "../../assets/images/Gastronomy/Card/whisky.jpg";
import glad from "../../assets/images/Gastronomy/Card/glad.jpg";
import ol from "../../assets/images/Gastronomy/Card/ol.jpg";
import ScrollAnimation from "react-animate-on-scroll";
import CardDetail from "../General/CardDetail";
import "./styles.scss";

import LaTorreInterior from "../../assets/images/Gastronomy/Card/Meridiano/01.jpg";
import LaTorrePortada from "../../assets/images/Gastronomy/Card/Meridiano/02.jpg";
import LaTorreProducto from "../../assets/images/Gastronomy/Card/Meridiano/03.jpg";
import LaTorreProducto2 from "../../assets/images/Gastronomy/Card/Meridiano/04.jpg";
import LaTorreProducto3 from "../../assets/images/Gastronomy/Card/Meridiano/05.jpg";
import DudesInterior from "../../assets/images/Gastronomy/Card/Dudes/01.jpg";
import DudesPortada from "../../assets/images/Gastronomy/Card/Dudes/02.jpg";
import DudesProducto from "../../assets/images/Gastronomy/Card/Dudes/03.jpg";
import DudesProducto2 from "../../assets/images/Gastronomy/Card/Dudes/04.jpg";
import OLInterior from "../../assets/images/Gastronomy/Card/OL/01.jpg";
import OLPortada from "../../assets/images/Gastronomy/Card/OL/02.jpg";
import OLProducto from "../../assets/images/Gastronomy/Card/OL/03.jpg";
import OLProducto2 from "../../assets/images/Gastronomy/Card/OL/04.jpg";
import OLProducto3 from "../../assets/images/Gastronomy/Card/OL/05.jpg";
import OLProducto4 from "../../assets/images/Gastronomy/Card/OL/06.jpg";
import RedInterior from "../../assets/images/Gastronomy/Card/Red/01.jpg";
import RedPortada from "../../assets/images/Gastronomy/Card/Red/02.jpg";
import RedProducto from "../../assets/images/Gastronomy/Card/Red/03.jpg";
import RedProducto2 from "../../assets/images/Gastronomy/Card/Red/04.jpg";
import RedProducto3 from "../../assets/images/Gastronomy/Card/Red/05.jpg";
import RedProducto4 from "../../assets/images/Gastronomy/Card/Red/06.jpg";
import RedProducto5 from "../../assets/images/Gastronomy/Card/Red/07.jpg";
import WhiskyInterior from "../../assets/images/Gastronomy/Card/Whisky/01.jpg";
import WhiskyPortada from "../../assets/images/Gastronomy/Card/Whisky/02.jpg";
import WhiskyProducto from "../../assets/images/Gastronomy/Card/Whisky/03.jpg";
import WhiskyProducto2 from "../../assets/images/Gastronomy/Card/Whisky/04.jpg";
import WhiskyProducto3 from "../../assets/images/Gastronomy/Card/Whisky/05.jpg";
import WhiskyProducto4 from "../../assets/images/Gastronomy/Card/Whisky/06.jpg";
import WhiskyProducto5 from "../../assets/images/Gastronomy/Card/Whisky/07.jpg";
import glad1 from "../../assets/images/Gastronomy/Card/Glad/01.jpg";
import glad2 from "../../assets/images/Gastronomy/Card/Glad/02.jpg";
import glad3 from "../../assets/images/Gastronomy/Card/Glad/03.jpg";
import glad4 from "../../assets/images/Gastronomy/Card/Glad/04.jpg";
import glad5 from "../../assets/images/Gastronomy/Card/Glad/05.jpg";

import teporinoportada from "../../assets/images/Gastronomy/Card/PORTADAS FICHAS/teporino.jpg";
import teporino1 from "../../assets/images/Gastronomy/Card/Teporino/teporino.jpg";
import teporino2 from "../../assets/images/Gastronomy/Card/Teporino/teporino2.jpg";
import teporino3 from "../../assets/images/Gastronomy/Card/Teporino/teporino3.jpg";
import teporino4 from "../../assets/images/Gastronomy/Card/Teporino/teporino4.jpg";
import teporino5 from "../../assets/images/Gastronomy/Card/Teporino/teporino5.jpg";


import Vinariumportada from "../../assets/images/Gastronomy/Card/PORTADAS FICHAS/Vinarium.png";
import Vinarium1 from "../../assets/images/Gastronomy/Card/Vinarium/Vinarium1.jpg";
import Vinarium2 from "../../assets/images/Gastronomy/Card/Vinarium/Vinarium2.jpg";
import Vinarium3 from "../../assets/images/Gastronomy/Card/Vinarium/Vinarium3.jpg";
import Vinarium4 from "../../assets/images/Gastronomy/Card/Vinarium/Vinarium4.jpg";
import Vinarium5 from "../../assets/images/Gastronomy/Card/Vinarium/Vinarium5.jpg";
import Vinarium6 from "../../assets/images/Gastronomy/Card/Vinarium/Vinarium6.jpg";
import Vinarium7 from "../../assets/images/Gastronomy/Card/Vinarium/Vinarium7.jpg";


import wichonportada from "../../assets/images/Gastronomy/Card/sanwichon/Sanwichon.jpg";
import WICHON1 from "../../assets/images/comercio/popups/Sanwichon_01.jpg"
import WICHON2 from "../../assets/images/comercio/popups/Sanwichon_02.jpg"
import WICHON3 from "../../assets/images/comercio/popups/Sanwichon_03.jpg"
import WICHON4 from "../../assets/images/comercio/popups/Sanwichon_04.jpg"
import WICHON5 from "../../assets/images/comercio/popups/Sanwichon_05.jpg"

import aidaportada from "../../assets/images/Gastronomy/Card/aida/Aida.jpg";
import aida1 from "../../assets/images/comercio/popups/aida-01.jpg"
import aida2 from "../../assets/images/comercio/popups/aida-02.jpg"
import aida3 from "../../assets/images/comercio/popups/aida-03.jpg"
import aida4 from "../../assets/images/comercio/popups/aida-04.jpg"
import aida5 from "../../assets/images/comercio/popups/aida-05.jpg"
import aida6 from "../../assets/images/comercio/popups/aida-06.jpg"

import pecosportada from "../../assets/images/comercio/popups/pecosportada.jpg"
import pecos1 from "../../assets/images/comercio/popups/pecos1.jpg"
import pecos2 from "../../assets/images/comercio/popups/pecos2.jpg"
import pecos3 from "../../assets/images/comercio/popups/pecos3.jpg"
import pecos4 from "../../assets/images/comercio/popups/pecos4.jpg"
import pecos5 from "../../assets/images/comercio/popups/pecos5.jpg"
import pecos6 from "../../assets/images/comercio/popups/pecos6.jpg"
import pecos7 from "../../assets/images/comercio/popups/pecos7.jpg"

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: 0,
    height: "100%",
    width: "100%",
    borderRadius: 0,
    overflowY: "auto",
    overflowX: "clip",
    border: "0px solid rgb(204, 204, 204)",
    zIndex: "5",
    boxShadow: "5px 5px 3px rgba(157, 157, 157, 0.7)",
    background: "transparent",
  },
  overlay: {
    zIndex: 5,
    background: "transparent",
  },
};

const modalStylesMobile = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: 0,
    height: "100%",
    width: "100%",
    borderRadius: 0,
    overflowY: "scroll",
    overflowX: "hidden",
    border: "0px solid rgb(204, 204, 204)",
    zIndex: "5",
    boxShadow: "5px 5px 3px rgba(157, 157, 157, 0.7)",
  },
  overlay: {
    zIndex: 4,
    backdropFilter: "blur(20px)",
    WebkitBackdropFilter: "blur(20px)",
  },
};
const restaurants = [
  {
    name: "RED ZEBRA",
    days: ["LUNES A DOMINGO"],
    time: ["11:00 A 22:00 H"],
    description: "RESTAURANTE | BAR",
    text: "Disfruta de un nuevo concepto con espacios al aire libre, para compartir con tus amigos. El buen ambiente se vive en Red Zebra.",
    especialidad: "Parrillada de res.",
    facebook: "https://www.facebook.com/RedZebraBar",
    instagram: "https://www.instagram.com/red_zebra_gt/?hl=es",
    menu: "https://www.facebook.com/RedZebraBar/menu",
    image: redZebra,
    images: [
      RedPortada,
      RedInterior,
      RedProducto,
      RedProducto2,
      RedProducto3,
      RedProducto4,
      RedProducto5,
    ],
  },
  {
    name: "DUDES",
    days: ["DOMINGO A MARTES", "MIÉRCOLES A SÁBADO"],
    time: ["12:00 A 20:00 H", "12:00 A 21:00 H"],
    description: "PIZZERÍA",
    text: "Bienvenidos a Dude's. NY Style pizzeria en Guatemala.  Best pizza in town. ",
    especialidad: "G.O.A.T : 6 FLAVOR PIZZA ",
    facebook: "https://www.facebook.com/dudespizzeria",
    instagram: "https://www.instagram.com/dudes.pizzeria",
    menu: "https://www.facebook.com/dudespizzeria/menu",
    image: dudes,
    images: [DudesPortada, DudesInterior, DudesProducto, DudesProducto2],
  },
  {
    name: "WHISKY DEN",
    days: ["LUNES A DOMINGO"],
    time: ["13:00 A 21:00 H"],
    description: "RESTAURANTE | BAR",
    text: "Bar con más de doscientas variedades de Whisky y Whisky puro de malta y más de quince variedades de cerveza.",
    especialidad:
      "Maker's Spritz: Maker's Mark Bourbon, Aperol, Mermelada de fresa, jugo de limón y soda.",
    facebook: "https://www.facebook.com/elwhiskyden",
    instagram: "https://www.instagram.com/el_whisky_den",
    menu: "",
    image: whisky,
    images: [WhiskyPortada, WhiskyInterior, WhiskyProducto, WhiskyProducto2, WhiskyProducto3, WhiskyProducto4, WhiskyProducto5],
  },
  {
    name: "GLAD",
    days: ["LUNES A SÁBADO", "DOMINGO"],
    time: ["7:00 A 21:00 H", "9:00 A 18:00 H"],
    description: "HELADERÍA",
    text: "Glad es el auténtico Yogurt Búlgaro, elaborado artesanalmente con altos estándares de calidad y con los más finos y mejores ingredientes frescos y naturales para así garantizar un óptimo producto, adicionado con sus cultivos importados de Bulgaria que lo hacen único y especial.",
    especialidad: "Yogurt Búlgaro",
    facebook: "https://www.facebook.com/gladgt",
    instagram: "https://www.instagram.com/gladguatemala/",
    menu: "",
    image: glad,
    images: [glad1, glad2, glad3, glad4, glad5],
  },
  {
    name: "MERIDIANO",
    days: ["LUNES A VIERNES", "SÁBADO", "DOMINGO"],
    time: ["7:00 A 19:00 H", "10:00 A 19:00 H", "10:00  17:00 H"],
    description: "COMIDA BALANCEADA",
    text: "Home of the smart food. Life happens, Meridiano helps. ",
    especialidad: "Magic Mushroom: Hummus clásico, hongos salteados y pan integral tostado.",
    facebook: "https://www.facebook.com/Meridiano-115930456925949",
    instagram: "https://www.instagram.com/meridiano.gt",
    menu: "https://www.instagram.com/s/aGlnaGxpZ2h0OjE3OTEwMjM1MDkxMDAxNTU3?story_media_id=2724328858659426217&utm_medium=copy_link",
    image: meridiano,
    images: [
      LaTorrePortada,
      LaTorreInterior,
      LaTorreProducto,
      LaTorreProducto2,
      LaTorreProducto3,
    ],
  },
  {
    name: "OL",
    days: ["MIÉRCOLES A DOMINGO", "LUNES Y MARTES"],
    time: ["14:00 A 21:00 H", "CERRADO"],
    description: "CENTRO CERVECERO",
    text: "Bar de Cervezas y Cócteles. Centro Cultural Cervecero con las mejores cervezas de Guatemala. Amor y cerveza, lo demás está de más. ",
    especialidad: "Cerveza buena y bien cuidada.",
    facebook: "",
    instagram: "https://www.instagram.com/centroculturalcervecero",
    menu: "https://untappd.com/v/ol/9775780",
    image: ol,
    images: [
      OLPortada,
      OLInterior,
      OLProducto,
      OLProducto2,
      OLProducto3,
      OLProducto4,
    ],
  },
  {
    name: "TEPORINGOS",
    days: ["LUNES A DOMINGO"],
    time: ["12:00 A 21:00 H"],
    description: "RESTAURANTE",
    text: "Traemos los sabores y delicias de Mexico a tu mesa, para disfrutar en familia y con amigos y compañeros de trabajo. Ideal también para después la oficina, fin de semana o cualquier antojo que tengas en la semana.",
    especialidad: "Mole Poblano",
    facebook: "https://www.facebook.com/teporingosgrill",
    instagram: "https://www.instagram.com/teporingosgrill/",
    menu: "https://www.facebook.com/teporingosgrill/menu",
    image: teporinoportada,
    images: [
      teporino1,
      teporino2,
      teporino3,
      teporino4,
      teporino5,
    ],
  },
  {
    name: "VINARIUM",
    days: ["LUNES A MIÉRCOLES", "JUEVES A SÁBADO","DOMINGO"],
    time: ["14:00H a 22:00H","14:00H a 24:00H","14:00H  a 20:00H"],
    description: "BAR",
    text: "Bar de vinos, cervezas y tablas de charcuterie. Café y postres.",
    especialidad: "Vino y tabla de charcuterie.",
    facebook: "",
    instagram: "https://www.instagram.com/vinariumgt/",
    menu: "",
    image: Vinariumportada,
    images: [
      Vinarium1,
      Vinarium2,
      Vinarium3,
      Vinarium4,
      Vinarium5,
      Vinarium6,
      Vinarium7
    ],
  },
  {
    name: "SAN WICHON",
    days: ["LUNES A MIÉRCOLES", "JUEVES A SÁBADO"],
    time: ["12:00H a 20:30H", "12:00H a 21:30H"],
    description: "RESTAURANTE",
    text: "Un tributo al buen pollo frito",
    especialidad: "Sandwich de pollo frito",
    facebook: "https://www.facebook.com/search/top?q=petspaw",
    instagram: "https://www.instagram.com/petspawgt/?hl=es",
    menu: "https://www.sanwichon.com/es/_files/ugd/a4ba33_0a09fbfdbde94d68899936ae7cc7b208.pdf",
    image: wichonportada,
    images: [
      WICHON1,
      WICHON2,
      WICHON3,
      WICHON4,
      WICHON5,
    ],
  },
  {
    name: "AIDA",
    days: ["LUNES A VIERNES", "DOMINGO"],
    time: ["07:00H a 20:30H","07:00H a 18:00H"],
    description: "RESTAURANTE",
    text: "Aída, un lugar agradable para disfrutar de una experiencia de comida hecha en casa",
    especialidad: "Tacos en salsa verde - Pizza",
    facebook: "https://www.facebook.com/search/top?q=petspaw",
    instagram: "https://www.instagram.com/petspawgt/?hl=es",
    menu: "https://www.aida.gt/restaurante/menuz10/",
    image: aidaportada,
    images: [
      aida1,
      aida2,
      aida3,
      aida4,
      aida5,
      aida6
    ],
  },
  {
    name: "PECOS BILL",
    days: ["LUNES A JUEVES", "VIERNES", "SÁBADO", "DOMINGO", "DELIVERY"],
    time: ["06:30 a 23:00 H", "07:00 A 24:00 H", "07:00 A 23:00 H","07:00 A 20:30 H"],
    description: "RESTAURANTE",
    text: "Con los mejores ingredientes y la misma calidad desde 1950, nuestro menú sorprenderá a tu paladar.",
    especialidad: "Hamburguesas: ¡Nunca han decepcionado a nadie! Todas son cocinadas a la parrilla.",
    facebook: "",
    instagram: "https://www.instagram.com/pecosbillgt/",
    menu: "https://www.pecosbill.com.gt/menu/",
    image: pecosportada,
    images: [
      pecos1, 
      pecos2, 
      pecos3, 
      pecos4,
      pecos5,
      pecos6,
      pecos7
    ],
  },
];

const modalStylesXxl = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: 0,
    height: "110vh",
    width: "100%",
    borderRadius: 0,
    overflowY: "auto",
    overflowX: "clip",
    zIndex: "5",
    boxShadow: "5px 5px 3px rgba(157, 157, 157, 0.7)",
    background: "transparent",
  },
  overlay: {
    zIndex: 5,
    background: "white",
  },
};

const GastronomyGeneral = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedRestaurant, setSelectedRestaurant] = useState(restaurants[0]);

  const { setValue } = props;

  const GastronomyCard = (props) => {
    const {
      image,
      name,
      description,

      onClick,
    } = props;

    return (
      <div className="col-6 col-md-4 p-md-2  my-2 my-md-0 my-restaurant-card-container">
        <ScrollAnimation
          animateIn="fadeIn"
          duration={0.5}
        >
          <div className="card my-restaurant-card h-100 w-100 m-0">
            <img src={image} className="card-img-top" alt="..." />
            <div className="card-body pb-0 my-restaurant-card-body text-center">
              <h4 className="card-title">{name}</h4>
              <p className="card-text">{description}</p>
              <p className="cross-image-card-gastronomy">
                <img
                  src={cruz}
                  alt=""
                  className="gastronomy-card-button"
                  onClick={() => onClick()}
                />
              </p>
            </div>
          </div>
        </ScrollAnimation>
      </div>
    );
  };

  const Gasmodal = (props) => {
    const { modalIsOpen, afterOpenModal, closeModal, selectedRestaurant } =
      props;
    const { width } = useWindowDimensions();

    return (
      <Modal
        isOpen={modalIsOpen}
        closeTimeoutMS={500}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={
          width <= 450
            ? modalStylesMobile
            : width <= 1700
              ? modalStyles
              : modalStylesXxl
        }
        contentLabel="Example Modal"
      >
        <button
          className="closemodalGas btn-close align-self-end"
          type="button"
          onClick={() => closeModal()}
        ></button>
        <CardDetail {...props} selectedRestaurant={selectedRestaurant} />
      </Modal>
    );
  };

  return (
    <div className="container-fluid align-items-center g-0 my-container-gastronomy">
      <Gasmodal
        modalIsOpen={modalOpen}
        closeModal={() => setModalOpen(false)}
        selectedRestaurant={selectedRestaurant}
      />

      <div className="row justify-content-center">
        {restaurants.map((value, index) => {
          return (
            <GastronomyCard
              onClick={() => {
                setSelectedRestaurant(value);
                setModalOpen(true);
              }}
              key={index}
              {...{ ...value, setValue }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default GastronomyGeneral;
