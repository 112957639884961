import React from "react";
import "./styles.scss";

import ApartmentsGeneral from "./General"

const Apartments = (props) => {
  console.log("I'm at /apartments");

 
  return (
    <div className='apartments-container' >
      <div className='apartments-box'>
        <div className='apartments-element' >
          <ApartmentsGeneral  {...props}  />
        </div>
      </div>
    </div>
  )
}

export default Apartments;