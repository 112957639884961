import React, { useRef } from "react";
import "./styles.scss";
import Detail from "./Detail";

const Workspaces = () => {
  const myRef = useRef();
  return (
    <div className="container-fluid g-0 my-container-workspaces" ref={myRef}>
      <div className="row g-0">
        <div className="col my-header-workspaces g-0" >
          <div className="quo-workspaces-random-box"/>
          <h1>
            WORK<br />AND ENJOY
          </h1>
        </div>
        <Detail />
      </div>
    </div> )
}

export default Workspaces;