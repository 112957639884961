import React from "react";
import './styles.scss';
import circulo from '../../assets/images/cultura/Trazado1717.svg'
import circulo2 from '../../assets/images/cultura/Trazado1838.svg'
import prev from '../../assets/images/cultura/Poligono2.svg'
import next from '../../assets/images/cultura/Poligono3.svg'
import plus from '../../assets/images/cultura/Grupo400.svg'
import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";
import Slider from "react-slick";
import axios from 'axios'
import Helmet from "react-helmet";

const meses = {
  '1': 'enero',
  '2': 'febrero',
  '3': 'marzo',
  '4': 'abril',
  '5': 'mayo',
  '6': 'junio',
  '7': 'julio',
  '8': 'agosto',
  '9': 'septiembre',
  '10': 'octubre',
  '11': 'noviembre',
  '12': 'diciembre',
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      src={next}
      alt="arrow-quo"
      className={className}
      style={{ ...style, display: "block", marginRight: '8%', marginTop: '-7%' }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      src={prev}
      alt="arrow-quo"
      className={className}
      style={{ ...style, display: "block", marginTop: '-7%' }}
      onClick={onClick}
    />
  );
}

export class CulturaQuo extends React.Component {

  constructor(props) {
    super(props)
    this.handleDayClick = this.handleDayClick.bind(this);
    
    this.state = {
      event: [],
      settings: {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />

      },
      settingsmobile: {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />

      },
      settingsgallery: {
        dots: false,
        infinite: true,
        speed: 200,
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,
      },
      selectedEvent: null,
      selectedDay: undefined,
      events: [],
      dates: [],
      bandera: false,
      bandera2: true,
      value: 6,
      banderaImportant: false,
      banderaVacio: false,
      banderaInicio: true
    }
  }

  getEvents = () => {
    axios.get(
      'https://testing-api-gaura.devpackgroup.com/api/v1/home/',
      {
        headers: {
          // "Authorization": "Token " + this.userToken,
        }
      }
    ).then(function (response) {
      this._clientsLoaded = true;
      this.setState({
        event: response.data,
        events: response.data
      })
      response.data.map(index => {
        if (index.flag === "A" || ((index.flag === "D") && (index.important === "I"))) {
          return (
            this.state.dates.push(new Date(index.date.slice(0, 4), (parseInt(index.date.slice(5, 7)) - 1), index.date.slice(8, 10))))
        }
      })

      response.data.map((value) =>{
        if(value.flag === "D"){
          this.setState({
            banderaImportant:true
          })
        }
        else{
          this.setState({
            banderaImportant:false
          })
        }
      })
    }.bind(this)).catch(function () {
    });
  }

  getDate = (date) => {
    const formData = new FormData();
    formData.append("date", date);
    axios.post(
      'https://testing-api-gaura.devpackgroup.com/api/v1/home/get_home_info/',
      formData,
      {
        headers: {
          // "Authorization": "Token " + this.userToken,
        }
      }
    ).then(function (response) {
      this.setState({
        events: response.data
      })
      if (response.data.length < 1){
        console.log("No hay eventos")
        this.setState({banderaVacio:true})
      }
      else{
        this.setState({banderaVacio:false})
      }
    }.bind(this)).catch(function () {
    });
  }

  componentDidMount() {
    this.getEvents();
  }
  handleDayClick(day) {
    let fecha = day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()
    console.log(day);
    this.setState({ selectedDay: day});
    this.getDate(fecha)
    this.setState({ bandera: true })
    this.setState({ banderaInicio: false })
    const a = document.getElementById("collapseExample")
    a.setAttribute("class","collapse")
  }

  render() {
    let fechas = {
      fe: this.state.dates
    }

    return (
      <div className="row g-0">
        <Helmet>
          <style>{`
          .DayPicker-Day--fe{
            border-color: #00bcd4;
            border-style: solid;
            border-radius: 100px;
            border-width: 1px;
            color: black;
      
          }
      
          `}</style>
        </Helmet>
        {console.log(this.state.event && this.state.event)}
        <div className="col" style={{ zIndex: "2" }}>
          <div className="cultura-container">
            <div className="row g-0" style={{ zIndex: "2" }}>
              <div className="col" style={{ zIndex: "2" }}>
                <div className="activities-line d-flex justify-content-center align-items-center">
                  <h3 className="mt-2">ACTIVIDADES DEL MES</h3>
                </div>
              </div>
            </div>
            <div className="row g-0" style={{ zIndex: "2" }}>
              <div className="col" style={{ zIndex: "2" }}>
                <div className="activities-container ">
                  <div className="row g-0 h-100">
                    <div className="col-6 col-lg-3 d-flex align-items-center h-100 justify-content-center">
                      <div className="calendar-div">
                        <a
                          data-bs-toggle="collapse"
                          href="#collapseExample"
                          aria-expanded="false"
                          aria-controls="collapseExample"
                        >
                          <h1 className="mt-2">CALENDARIO</h1>
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-9 col-6 d-none d-lg-block">
                      <div className="carousel">
                        <Slider {...this.state.settings}>
                          {this.state.event.map((value, index) => {
                            if (value.flag === "A") {
                              return (
                                <div key={index} className="event-container d-flex justify-content-center align-items-center flex-column mt-4">
                                  <h2>
                                    {[
                                      new Date(value.date).getMonth() + 1 ===
                                        9 &&
                                      new Date(value.date).getDate() + 1 === 31
                                        ? "1"
                                        : new Date(value.date).getDate() + 1,
                                    ]}{" "}
                                    de{" "}
                                    {
                                      meses[
                                        new Date(value.date).getMonth() + 1 ===
                                          9 &&
                                        new Date(value.date).getDate() + 1 ===
                                          31
                                          ? new Date(value.date).getMonth() + 2
                                          : new Date(value.date).getMonth() + 1
                                      ]
                                    }
                                  </h2>
                                  <img src={value.logo} className="mt-1"></img>
                                </div>
                              );
                            }
                          })}
                        </Slider>
                      </div>
                    </div>
                    <div className="col-lg-9 col-6 d-block d-lg-none">
                      <div className="carousel">
                        <Slider {...this.state.settingsmobile}>
                          {this.state.event.map((value, index) => {
                            if (value.flag === "A") {
                              return (
                                <div key={index} className="event-container d-flex justify-content-center align-items-center flex-column mt-lg-4 mt-1">
                                  <h2>
                                    {[
                                      new Date(value.date).getMonth() + 1 ===
                                        9 &&
                                      new Date(value.date).getDate() + 1 === 31
                                        ? "1"
                                        : new Date(value.date).getDate() + 1,
                                    ]}{" "}
                                    de{" "}
                                    {
                                      meses[
                                        new Date(value.date).getMonth() + 1 ===
                                          9 &&
                                        new Date(value.date).getDate() + 1 ===
                                          31
                                          ? new Date(value.date).getMonth() + 2
                                          : new Date(value.date).getMonth() + 1
                                      ]
                                    }
                                  </h2>
                                  <img src={value.logo}></img>
                                </div>
                              );
                            }
                          })}
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="collapse" id="collapseExample">
              <div className="card card-body">
                <div className="row g-0">
                  <div className="col-lg-3 offset-lg-1">
                    <DayPicker
                      onDayClick={this.handleDayClick}
                      selectedDays={this.state.selectedDay}
                      modifiers={fechas}
                    >
                      <a
                        id="close"
                        data-bs-toggle="collapse"
                        href="#collapseExample"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                      ></a>
                    </DayPicker>
                  </div>
                </div>
                <div
                  className="spinner-border text-center d-none d-lg-block"
                  role="status"
                  style={{ color: "#f8e12e", marginLeft: "50%" }}
                ></div>
              </div>
            </div>
            <div
              className="modal"
              id="modalActivities"
              tabindex="-1"
              aria-labelledby="modalActivitiesLabel"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body">
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                    <p>
                      The modal body is where all the text, images, and links
                      go.
                    </p>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal "
              id="myModal"
              tabindex="-1"
              aria-labelledby="myModalLabel"
            >
              <div className="modal-dialog modal-dialog-centered marginModal">
                <div className="card card-body-activities d-none d-lg-block d-lg-flex">
                  <div className="row g-0">
                    <div className="col-lg-9">
                      <div className="modal-activitie">
                        <div className="row g-0 w-100 h-100">
                          <div className="col-lg-6 d-flex justify-content-center flex-column align-items-center">
                            <div className="imageModal">
                              <img
                                src={
                                  this.state.selectedEvent &&
                                  this.state.selectedEvent.logo
                                }
                              />
                            </div>
                            <h2>
                              {[
                                new Date(this.state.selectedEvent && this.state.selectedEvent.date).getMonth() + 1 === 9 &&
                                new Date(this.state.selectedEvent &&this.state.selectedEvent.date).getDate() + 1 === 31
                                  ? "1"
                                  : new Date(this.state.selectedEvent && this.state.selectedEvent.date).getDate() + 1,
                              ]}{" "}
                              DE{" "}
                              {meses[
                                new Date(this.state.selectedEvent && this.state.selectedEvent.date).getMonth() + 1 === 9 &&
                                new Date(this.state.selectedEvent && this.state.selectedEvent.date).getDate() + 1 === 31
                                  ? new Date(this.state.selectedEvent &&
                                        this.state.selectedEvent.date
                                    ).getMonth() + 2
                                  : new Date(this.state.selectedEvent &&
                                        this.state.selectedEvent.date
                                    ).getMonth() + 1
                              ] +
                                " " +
                                new Date(
                                  this.state.selectedEvent &&
                                    this.state.selectedEvent.date
                                ).getFullYear()}
                            </h2>
                            <h3>
                              {this.state.selectedEvent &&
                                this.state.selectedEvent.time}
                            </h3>
                            <p>
                              {this.state.selectedEvent &&
                                this.state.selectedEvent.description}
                            </p>
                          </div>
                          <div className="col-lg-6">
                            <div
                              className="img-container h-100 m-0"
                              style={{
                                backgroundImage: `url(${
                                  this.state.selectedEvent &&
                                  this.state.selectedEvent.image_modal
                                })`,
                                borderRadius: "25px",
                                backgroundSize: "cover",
                              }}
                            >
                              <div className="row">
                                <div className="col d-flex justify-content-end">
                                  <a data-dismiss="modal">
                                    <button
                                      type="button"
                                      className="btn-close btn-close-white"
                                      aria-label="Close"
                                    ></button>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card card-body-activities d-flex d-lg-none">
                  <div className="row g-0 w-100 h-100 d-block d-lg-none">
                    <div className="col-12">
                      <div
                        className="img-container m-0"
                        style={{
                          backgroundImage: `url(${
                            this.state.selectedEvent &&
                            this.state.selectedEvent.image_modal
                          }) `,
                          borderRadius: "25px",
                          height: "35vh",
                          objectFit: "cover",
                          backgroundSize: "cover",
                          backgroundPosition: "center center",
                        }}
                      >
                        <div className="row">
                          <div className="col d-flex justify-content-end">
                            <a
                              data-bs-toggle="modal"
                              data-dismiss="modal"
                              aria-expanded="false"
                              aria-controls="collapseActivities"
                            >
                              <button
                                type="button"
                                className="btn-close btn-close-white"
                                aria-label="Close"
                                style={{
                                  marginTop: "-125px",
                                  marginRight: "-16px",
                                  opacity: "1",
                                }}
                              ></button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-0 w-100 h-100 d-block d-lg-none">
                    <div className="col-lg-8 d-flex justify-content-center flex-column align-items-center">
                      <img
                        src={
                          this.state.selectedEvent &&
                          this.state.selectedEvent.logo
                        }
                        style={{
                          width: "210px",
                          height: "100px",
                          objectFit: "contain",
                          marginTop: "20px",
                          marginBottom: "20px",
                        }}
                      />
                      <h2
                        style={{ color: "#05aca0", fontFamily: "FuturaBold" }}
                      >
                        {[
                                new Date(this.state.selectedEvent && this.state.selectedEvent.date).getMonth() + 1 === 9 &&
                                new Date(this.state.selectedEvent &&this.state.selectedEvent.date).getDate() + 1 === 31
                                  ? "1"
                                  : new Date(this.state.selectedEvent && this.state.selectedEvent.date).getDate() + 1,
                              ]}{" "}
                              DE{" "}
                              {meses[
                                new Date(this.state.selectedEvent && this.state.selectedEvent.date).getMonth() + 1 === 9 &&
                                new Date(this.state.selectedEvent && this.state.selectedEvent.date).getDate() + 1 === 31
                                  ? new Date(this.state.selectedEvent &&
                                        this.state.selectedEvent.date
                                    ).getMonth() + 2
                                  : new Date(this.state.selectedEvent &&
                                        this.state.selectedEvent.date
                                    ).getMonth() + 1
                              ].toUpperCase() +
                          " " +
                          new Date(
                            this.state.selectedEvent &&
                              this.state.selectedEvent.date
                          ).getFullYear()}
                      </h2>
                      <h3
                        style={{
                          color: "#05aca0",
                          fontSize: "11px",
                          fontFamily: "FuturaMedium",
                        }}
                      >
                        {this.state.selectedEvent &&
                          this.state.selectedEvent.time}
                      </h3>
                      <p
                        style={{
                          fontSize: "9.2px",
                          width: "90%",
                          height: "110px",
                          marginTop: "10px",
                          textAlign: "justify",
                        }}
                      >
                        {this.state.selectedEvent &&
                          this.state.selectedEvent.description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0" style={{ zIndex: "2" }}>
              <div
                className="col"
                style={{ zIndex: "2", backgroundColor: "#ffffff" }}
              >
                <img src={circulo} className="medio-circulo"></img>
              </div>
            </div>
            <div style={{ zIndex: "2", backgroundColor: "#ffffff" }}>
              <div className="row paddingDiv g-0 justify-content-center">
               {/*  <h1
                  className={`${this.state.banderaInicio ? "" : "d-none"}`}
                  style={{
                    color: "gray",
                    marginBottom: "130px",
                    paddingTop: "140px",
                  }}
                ></h1> */}
                {(this.state.events.length < 1 ||
                  this.state.banderaImportant) && (
                  <h1
                    className={`${
                      this.state.banderaVacio ? "" : "d-none"
                    } text-center`}
                    style={{
                      color: "gray",
                      marginBottom: "130px",
                      paddingTop: "140px",
                    }}
                  >
                    {this.state.banderaVacio ? "NO EXISTE EVENTO" : ""}
                    <br></br>
                    {this.state.banderaVacio ? "EN LA FECHA SELECCIONADA" : ""}
                  </h1>
                )}
                {this.state.events.map((value, index) => {
                  if (value.flag === "A") {
                    return (
                      <div
                        key={index}
                        className="col-6 col-lg-4 DivCard"
                        style={{ zIndex: "2", backgroundColor: "#ffffff" }}
                      >
                        <div
                          className="card cultura-card m-0"
                          style={{ backgroundImage: `url(${value.image})` }}
                        >
                          <div className="row">
                            <div className="col DivPlus d-flex justify-content-end">
                              <button
                                type="button"
                                className="btn btn-primary p-0"
                                data-bs-toggle="modal"
                                data-bs-target="#myModal"
                                style={{
                                  backgroundColor: "transparent",
                                  borderColor: "transparent",
                                  boxShadow: "none",
                                  outline: "none",
                                }}
                              >
                                <img
                                  src={plus}
                                  alt="plus-quo"
                                  onClick={() => {
                                    console.log(value.date);
                                    this.setState({ selectedEvent: value });
                                  }}
                                ></img>
                              </button>
                            </div>
                          </div>
                        </div>
                        <h4 className="text-center mt-4">{value.name}</h4>
                        <h5 className="text-center">
                          {[
                            new Date(value.date).getMonth() + 1 === 9 &&
                            new Date(value.date).getDate() + 1 === 31
                              ? "1"
                              : new Date(value.date).getDate() + 1,
                          ]}{" "}
                          DE{" "}
                          {
                            meses[
                              new Date(value.date).getMonth() + 1 === 9 &&
                              new Date(value.date).getDate() + 1 === 31
                                ? new Date(value.date).getMonth() + 2
                                : new Date(value.date).getMonth() + 1
                            ]
                          }
                        </h5>
                        <h5 className="text-center">{value.time}</h5>
                      </div>
                    );
                  } else if (
                    value.flag === "D" &&
                    value.important === "I" &&
                    this.state.bandera === true
                  ) {
                    return (
                      <div
                        className="col-6 col-lg-4  p-2  DivCard"
                        style={{ zIndex: "2", backgroundColor: "#ffffff" }}
                      >
                        <div
                          className="card cultura-card m-0"
                          style={{ backgroundImage: `url(${value.image})` }}
                        >
                          <div className="row">
                            <div className="col DivPlus d-flex justify-content-end">
                              <button
                                type="button"
                                className="btn btn-primary p-0"
                                data-bs-toggle="modal"
                                data-bs-target="#myModal"
                                style={{
                                  backgroundColor: "transparent",
                                  borderColor: "transparent",
                                  boxShadow: "none",
                                  outline: "none",
                                }}
                              >
                                <img
                                  src={plus}
                                  alt="plus-quo"
                                  onClick={() => {
                                    console.log(value.date);
                                    this.setState({ selectedEvent: value });
                                  }}
                                ></img>
                              </button>
                            </div>
                          </div>
                        </div>
                        <h4 className="text-center mt-4">{value.name}</h4>
                        <h5 className="text-center">
                          {[
                            new Date(value.date).getMonth() + 1 === 9 &&
                            new Date(value.date).getDate() + 1 === 31
                              ? "1"
                              : new Date(value.date).getDate() + 1,
                          ]}{" "}
                          de{" "}
                          {
                            meses[
                              new Date(value.date).getMonth() + 1 === 9 &&
                              new Date(value.date).getDate() + 1 === 31
                                ? new Date(value.date).getMonth() + 2
                                : new Date(value.date).getMonth() + 1
                            ]
                          }
                        </h5>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
            <div className="row g-0" style={{ zIndex: "2" }}>
              <div
                className="col d-flex justify-content-center"
                style={{ zIndex: "2", backgroundColor: "#ffffff" }}
              >
                <img src={circulo2} className="medio-circulo ms-5"></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CulturaQuo;