import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation
} from "react-router-dom";
import Home from "../Home";
import Business from "../Business";
import Gastronomy from "../GastronomyGeneral";
import QuoSpaces from "../QuoSpaces";
import QuoSpacesDetail from "../QuoSpaces/Detail";
import Workspaces from "../Workspaces";
import Galery from "../Galery";
import Footer from "../Footer";
import Contact from "../Contact";
import "./styles.scss";
import { ParallaxProvider } from "react-scroll-parallax";

import Apartments from "../Apartments";
import QuoWorkspacesDetail from "../Workspaces/Detail";
import CulturaQuo from "../CulturaQuo";


const HOME = (props) => (
  <ParallaxProvider>
    <div className="background">
      <Galery {...props} />
      <Footer />
    </div>
  </ParallaxProvider>
)

const APARTMENTS = () => (
  <div className="background">
    <Apartments />
    <Footer />
  </div>
)

const WORKSPACES = () => (
  <div className="background">
    <Workspaces />
    <Footer />
  </div>
)

const WORKSPACES_DETAIL = () => (
  <div className="background">
    {/* <Home /> */}
    <QuoWorkspacesDetail />
    <Footer />
  </div>
);

const BUSINESS = () => (
  <div className="background">
    <Business />
    <Footer />
  </div>
)

const GASTRONOMY = () => (
  <div className="background">
    <Gastronomy />
    <Footer />
  </div>

)

const QUOSPACES = () => (
  <div className="background">
    <QuoSpaces />
    <Footer />
  </div>
)

const QUOSPACES_DETAIL = () => (
  <div className="background">
    <QuoSpacesDetail />
    <Footer />
  </div>
);

const CONTACT = (props) => (
  <div className="background">
    <Contact {...props} />
    <Footer />
  </div>
)

const CULTURA = (props) => (
  <div className="background">
    <CulturaQuo {...props} />
    <Footer />
  </div>
)
const App = () => {
  const location = useLocation()
  useEffect(() => window.scrollTo(0, 0), [location]);
  const [selectedValue, setSelectedValue] = useState(-1);
  return (
    <Router>
      <Home />

      <Switch>
        <Route path="/apartamentos">
          <APARTMENTS />
        </Route>
        <Route path="/workspaces/detail">
          <WORKSPACES_DETAIL />
        </Route>
        <Route path="/workspaces">
          <WORKSPACES />
        </Route>
        <Route path="/comercio">
          <BUSINESS />
        </Route>
        <Route path="/gastronomia">
          <GASTRONOMY />
        </Route>
        <Route path="/espacios-quo/detail">
          <QUOSPACES_DETAIL />
        </Route>
        <Route path="/espacios-quo">
          <QUOSPACES />
        </Route>
        <Route path="/cultura-quo">
          <CULTURA selectedValue={selectedValue} setSelectedValue={setSelectedValue} />
        </Route>
        <Route path="/contacto">
          <CONTACT selectedValue={selectedValue} setSelectedValue={setSelectedValue} />
        </Route>
        <Route path="/home">
          <HOME selectedValue={selectedValue} setSelectedValue={setSelectedValue} />
        </Route>
        <Route path="/">
          <HOME selectedValue={selectedValue} setSelectedValue={setSelectedValue} />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;