/* eslint-disable no-restricted-globals */
import React from "react";
import "./styles.scss";
import circle from "../../../assets/images/gastronomia/circle.svg"
import phone from "../../../assets/images/gastronomia/phone.svg"
import fb from "../../../assets/images/gastronomia/fb.svg"
import inst from "../../../assets/images/gastronomia/inst.svg"
import Slider from "react-slick";
import Footer from "../../Footer"

const CardDetail = (props) => {
  const {
    selectedRestaurant,
  } = props;
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    fade: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
    arrows: false,
  };

  return (
    // eslint-disable-next-line no-restricted-globals
    <div className={`divContainer ${(selectedRestaurant.name === "ROSA ALACRÁN") && "rosaMargin"} ${(selectedRestaurant.name === "ROSA ALACRÁN" && screen.width < 500) && "rosaFooter"}`} style={{ height: `${selectedRestaurant.name === "ROSA ALACRÁN" && screen.width > 1299 ? "910px" : `${selectedRestaurant.name === "OL" ? "1060px" : `${selectedRestaurant.name === "SIMON'S" ? "970px" : `${selectedRestaurant.name === "WHISKY DEN" ? "1055px" : `${selectedRestaurant.name === "GLAD" ? "760px" : "1030px"}`}`}`}`}` }}>
      <div className='row g-0 pop-up no-gutters'>
        <div className='col col-lg-6 left-col'>
          <div className='circle'>
            <img src={circle} alt="" />
          </div>
          <div className='row no-gutters '>
            <div className='col-10 col-lg-7  offset-lg-1 col-sm offset-1'>
              <p className='titleCard'>{selectedRestaurant.name}</p>
            </div>
            <div className='col col-lg-2 offset-lg-0 col-sm-2 offset-1 d-none d-md-block g-0'>
              <div className='row justify-content-end divSocial'>
                {selectedRestaurant.facebook && <img src={fb} alt="" className="g-0" onClick={() => window.location.assign(selectedRestaurant.facebook)} />}
                {selectedRestaurant.instagram && <img src={inst} alt="" className="g-0" onClick={() => window.location.assign(selectedRestaurant.instagram)} />}
              </div>
            </div>
          </div>
          <div className='row no-gutters '>
            <div className='col-10 col-lg-5 offset-lg-1 col-sm offset-1 pr-0'>
              <p className='restaurant'>{selectedRestaurant.description}</p>
            </div>
          </div>
          <div className='row no-gutters  g-0 mb-md-2'>
            {
              selectedRestaurant.days.map((day, index) => (
                <div className='row no-gutters' key={index}>
                  <div className='col'>
                    <div className='col offset-2'>
                      <p className='time mt-lg-2'>{day}</p>
                    </div>
                  </div>
                  <div className='col'>
                    <div className='col col-lg-6 offset-lg-0 col-sm offset-1'>
                      <p className='time mt-lg-2'>{selectedRestaurant.time[index]}</p>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
          <div className='row no-gutters '>
            <div className='col-6 col-lg-5 offset-lg-1 col-sm offset-1'>
              <div className='divPhone' style={{ display: "none" }}>
                <img src={phone} alt="" />
                <p>(+502) 5510-8752</p>
              </div>
            </div>
            <div className='col offset-lg-1 col-sm offset-2 d-block d-lg-none'>
              <div className='row divSocial'>
                {selectedRestaurant.facebook && <img src={fb} alt="" onClick={() => window.location.assign(selectedRestaurant.facebook)} />}
                {selectedRestaurant.instagram && <img src={inst} alt="" onClick={() => window.location.assign(selectedRestaurant.instagram)} />}
              </div>
            </div>
          </div>
          <div className='row no-gutters '>
            <div className='col col-dm-8 d-block d-lg-none'>
              <div className='carousel'>
                <Slider {...settings}>
                  {
                    selectedRestaurant.images && selectedRestaurant.images.length !== 0 && selectedRestaurant.images.map((value, index) => (
                      <img key={index} src={value} className='d-block ' alt='...' />
                    ))}
                </Slider>

              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-10 col-lg-9 offset-lg-1 col-sm offset-1'>
              <p className='info mt-lg-3'>
                {selectedRestaurant.text}
              </p>
            </div>
          </div>
          {selectedRestaurant.especialidad !== "" &&
            <>
              <div className='row'>
                <div className='col-5 offset-lg-1 col-sm offset-1'>
                  <p className='info2 mt-lg-3'>
                    ESPECIALIDAD:
                  </p>
                </div>
                <div className='col-3 offset-2  d-block d-lg-none offset-lg-0 col-lg-2'>
                  <div className='recta'>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-10 col-lg-9 offset-lg-1 col-sm offset-1'>
                  <p className='info3 mt-lg-3'>
                    {selectedRestaurant.especialidad}
                  </p>
                </div>
              </div>
            </>
          }
          <div className='row'>

            <div className={`col-4 col-lg-7 offset-lg-1 col-sm offset-4 ${((selectedRestaurant.name === "OL" && screen.width < 450) || selectedRestaurant.name === "WHISKY DEN") && "d-none"}`}>
              <div className='back' onClick={() => window.location.assign(selectedRestaurant.menu)} >
                <p>VER MENÚ</p>
              </div>
            </div>
            <div className='col offset-4  d-none d-lg-block offset-lg-0 col-lg-2  recta-container'>
              <div className='recta'>
              </div>
            </div>
          </div>
          <div className=" popup-footer" id="popup-footer">
            <Footer />
          </div>
        </div>
        <div className='col col-lg-5 offset-lg-0  d-none d-lg-block'>
          <div className='carousel'>
            <Slider {...settings}>
              {
                selectedRestaurant.images && selectedRestaurant.images.length !== 0 && selectedRestaurant.images.map((value, index) => (
                  <img key={index} src={value} className='d-block ' alt='...' />
                ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardDetail