/* eslint-disable no-restricted-globals */
import React, {useRef} from "react";
import Navbar from "../NavBar";
import "./styles.scss";
import "font-awesome/css/font-awesome.min.css";
import { useLocation } from "react-router-dom"

import p01 from "../../assets/images/fondoHome.jpg";
import p02 from "../../assets/images/fondo-home-1.png";
import wfondo from "../../assets/images/wfondo.png";
import smoothscroll from "smoothscroll-polyfill";
import ReactPlayer from "react-player"

// kick off the polyfill!
smoothscroll.polyfill();

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const scrollToRef = (ref) => {
  const { height } = getWindowDimensions();
  const { width } = getWindowDimensions();
  if(width>1500 && width < 1800 ) {
    setTimeout(window.scroll({top: ref.current.offsetHeight - height*0.15 , behavior: "smooth"}), 1000)
  }
  if(width > 1800){
    setTimeout(window.scroll({top: ref.current.offsetHeight - height*0.14 , behavior: "smooth"}), 1000)
  }
  if(width < 1500){
    setTimeout(window.scroll({top: ref.current.offsetHeight - height*0.165 , behavior: "smooth"}), 1000)
  }
}

const Carousel = (props) => {
  const {
    reference
  } = props;
  return (
    <>
      <div id="carouselExampleControls" className="carousel slide my-carousel d-none d-lg-block" data-bs-ride="carousel">
        <ReactPlayer url="https://www.youtube.com/embed/7364Nyea34w?vq=hd720" loop={true} playing={true} muted={true} playsinline={true} 
          width={`${(screen.width > 1200 && screen.width < 1400 && screen.height > 660 ) ? "105%" : "100%"}`} height="100%" controls={false} style={{pointerEvents: "none", position: "absolute", marginLeft: `${(screen.width > 1200 && screen.width < 1400 && screen.height > 660 ) ? "-20px" : "0px"}` }}></ReactPlayer>
      </div>
      <div id="carouselExampleControls" className="carousel slide my-carousel d-block d-lg-none" data-bs-ride="carousel">
        <ReactPlayer url="https://www.youtube.com/embed/7364Nyea34w?vq=hd720" loop={true} playing={true} muted={true} playsinline={true} 
          width="400%" height="100%" controls={false} style={{pointerEvents: "none", position: "absolute", marginLeft: "-152%"}}></ReactPlayer>
      </div>
      <div className="my-arrow">
        <i className="fa fa-angle-down animated bounce" onClick={() => scrollToRef(reference)}></i>
      </div>
    </>
  )
}

const Home = () => {
  const myRef = useRef(null)
  const location = useLocation();
  const path = location.pathname;
  let styles = "row row-sm-1 g-0 justify-content-center my-carousel-container";
  let images = [p01, p02, p01, p02]
  if (path==="/apartamentos") {
    images=[p02, p02]
  }
  if (path==="/workspaces") {
    images=[wfondo, wfondo]
  }
  return (
    <div ref={myRef} style={{scrollBehavior: "smooth"}}>
      <Navbar />
      <div className={styles}>
        {(location.pathname === "/") && <Carousel images={images} reference={myRef} />}
      </div>
    </div>
  )
}

export default Home;