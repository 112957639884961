import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import "./styles.scss";
import axios from "axios";

import plus from "../../../assets/images/apartamentos/general/plus.svg"
import mts from "../../../assets/images/apartamentos/general/mts.svg"
import roomsA from "../../../assets/images/apartamentos/general/rooms.svg"
import bathroomsimg from "../../../assets/images/apartamentos/general/bathrooms.svg"

import Detail from "../Detail";
import Footer from "../../Footer"
import circle0 from "../../../assets/images/apartamentos/general/circle0.svg"


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const modalStylesMobile = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: 0,
    height: "100%",
    width: "100%",
    borderRadius: 0,
    overflowY: "auto",
    overflowX: "clip",
    zIndex: "5",
    boxShadow: "5px 5px 3px rgba(157, 157, 157, 0.7)",
  },
  overlay: {
    zIndex: 4,
    backdropFilter: "blur(20px)",
    WebkitBackdropFilter: "blur(20px)"
  }

}

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: 0,
    height: "105%",
    width: "100%",
    border: 0,
    borderRadius: 0,
    overflowY: "auto",
    overflowX: "clip",
    zIndex: "5",
    boxShadow: "5px 5px 3px rgba(157, 157, 157, 0.7)",
  },
  overlay: {
    zIndex: 4,
    backdropFilter: "blur(15px)",
    WebkitBackdropFilter: "blur(15px)",
  }
};


const ApartmentsGeneral = (props) => {

  useEffect(() => {
    getApartments()
  }, []);

  const [apartamentos, setApartamentos] = useState([])

  const getApartments = () => {
    axios.get(
      "https://testing-api-gaura.devpackgroup.com/api/v1/apartment/",
      {
        headers: {
          // "Authorization": "Token " + this.userToken,
        }
      }
    ).then(function (response) {
      setApartamentos(response.data)
    }).catch(function () {
    });
  }
  useWindowDimensions(); //width <= 768
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedAparment, setSelectedAparment] = useState(apartamentos[0]);

  const ApartmentCard = (props) => {
    const {
      image,
      apt_name,
      metraje,
      rooms,
      bathrooms,
      onClick
    } = props;
    return (
      <div className='col-6 col-md-3  my-apartment-card-container'>
        <div className="card my-apartment-card h-100 w-100 m-0">
          <div className="card-img-top apartment-card-carousel-container" alt="...">
            <div className='image-aparment-card'>
              <img src={image} alt='' />
            </div>
          </div>
          <div className="card-body pb-0 my-apartment-card-body text-center">
            <h4 className="card-title mb-md-3 mt-md-3">{apt_name}</h4>

            <div className='row g-0 row-margin justify-content-center'>
              <div className='icon-atribute-container'>
                <img alt='' src={mts} className='apartment-info-icon-mts' />
                <p>{metraje} METRAJE</p>
              </div>
            </div>
            <div className='row g-0 row-margin justify-content-center'>
              <div className='icon-atribute-container'>
                <img alt='' src={roomsA} className='apartment-info-icon-rooms' />
                <p>{rooms} {rooms > 1 ? "HABITACIONES": "HABITACIÓN"}</p>
              </div>
            </div>
            <div className='row g-0 justify-content-center'>
              <div className='icon-atribute-container'>
                <img alt='' src={bathroomsimg} className='apartment-info-icon-bathrooms' />
                <p className="">{bathrooms} {bathrooms >1 ? "BAÑOS" :"BAÑO"} </p>
              </div>
            </div>

            <p className="more-info-apartment-card" onClick={() => onClick()}
            >
              MÁS INFORMACIÓN <img src={plus} alt='' />
            </p>
          </div>
        </div>
      </div>

    )
  }

  const Aptmodal = (props) => {
    const {
      modalIsOpen,
      afterOpenModal,
      closeModal,
    } = props;
    const { width } = useWindowDimensions();

    return (

      <Modal
        isOpen={modalIsOpen}
        closeTimeoutMS={500}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={width <= 450 ? modalStylesMobile : modalStyles}
        contentLabel="Example Modal"
      >
        <button
          className='btn-close closemodal-apart align-self-end'
          type="button"
          onClick={() => closeModal()} />

        <Detail {...props} selectedAparment={selectedAparment} />
        <div className="popup-footer-apt" id="popup-footer">
          <Footer />
        </div>
      </Modal>
    )
  }
  const {
    setValue,
  } = props;
  return (
    <div className='container-fluid pt-0 align-items-center g-0 my-container-apartments-general'>
      <Aptmodal modalIsOpen={modalOpen} closeModal={() => setModalOpen(false)} />

      <img src={circle0} alt="" className='circle0-apartments' />
      <div className='row g-0'>
        <div className='col g-0 awesome-place'>
          <h1>LIVE IN AN<br />AWESOME PLACE</h1>
        </div>
        <div className='col-12 col-md-7 g-0 pb-1 d-flex flex-column justify-content-between'>
          <div className='row-5  g-0 random-box-apartments' />
          <div className='
            row
            mb-4
            g-0
            align-items-center
            offset-lg-2
            apartments-filters'>
            <div className='filters col-4 col-lg-3'>
              <div className='filters-text '>
                HABITACIONES
              </div>
              <div className='col-2 col-sm-2 g-0'>
                <select className='select-top'>
                  <option selected disabled hidden value=""></option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                </select>
              </div>
            </div>
            <div className='filters col-4 col-lg-3'>
              <div className='filters-text text-left ps-md-2'>
                COMPRA-RENTA
              </div>
              <div className='col-2 col-sm-2 g-0'>
                <select className='select-top'>
                  <option selected disabled hidden value=""></option>
                  <option value="1">COMPRA</option>
                  <option value="2">RENTA</option>
                </select>
              </div>
            </div>
            <div className='filters col-4 col-lg-3'>
              <div className='filters-text text-left ps-md-2'>
                AMUEBLADO
              </div>
              <div className='col-2 col-sm-2 g-0'>
                <select className='select-top select-top-last'>
                  <option selected disabled hidden value=""></option>
                  <option value="1">SI</option>
                  <option value="2">NO</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row 
      justify-content-center
      
      '>
        {
          apartamentos.map((value, key) => {
            return (
              <ApartmentCard key={key} onClick={() => {
                console.log(value)
                setSelectedAparment(value);
                setModalOpen(true);
              }}
              {...{ ...value, setValue }} />
            );
          })
        }

        <div className='d-none d-lg-block row-5  g-0 random-line' />
      </div>
      <img src={circle0} alt="" className='circle1-apartments' />
      <img src={circle0} alt="" className='circle2-apartments' />
    </div>
  )
}

export default ApartmentsGeneral;